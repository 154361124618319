import React from 'react';
import { take } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { Show } from 'wui/Show';
import { Stack } from 'wui/Stack';
import { Tooltip } from 'wui/Tooltip';
import { Typography } from 'wui/Typography';
import { ToggleButton } from 'wui/ToggleButton';

import { selectReactionsMap } from '../../ReactedMembers/selectors';

import { ReactionIcon } from './ReactionIcon';

import classes from './Reaction.scss';

interface IProps
  extends Omit<React.ComponentProps<typeof ToggleButton>, 'icon'> {
  code: string;
  reacted: boolean;
  feedItemId: string;

  onClick(): void;
}

export const Reaction: React.FC<IProps> = (props) => {
  const { code, feedItemId, reacted, className, onClick, ...rest } = props;

  const { t } = useTranslation();
  const reactions = useSelector(selectReactionsMap(feedItemId));

  const summary = reactions[code];

  const ReactedUsers = summary?.length ? (
    <Stack direction="vertical">
      {take(summary, 20).map((reaction) => (
        <Typography noWrap variant="p2-12" key={reaction.user.memberId}>
          {reaction.user.name}
        </Typography>
      ))}
      <Show if={summary.length > 20}>
        <span>...</span>
      </Show>
    </Stack>
  ) : null;

  return (
    <Tooltip content={ReactedUsers}>
      <ToggleButton
        {...rest}
        checked={reacted}
        onChange={onClick}
        data-hook="feed-item-reaction"
        className={classes.root}
        label={(summary?.length || 0).toString()}
        icon={<ReactionIcon code={code} reacted={reacted} />}
        aria-label={
          reacted
            ? t('groups-web.a11y.reactions.unreact')
            : t('groups-web.a11y.reactions.react')
        }
      />
    </Tooltip>
  );
};

Reaction.displayName = 'Reaction';
