import React, { useState } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useCurrentStateAndParams } from '@wix/tpa-router/react';

import { EFilterKeys, IFeedItem } from 'api/feed';

import { Tag as TagIcon } from '@wix/wix-ui-icons-common/on-stage';
import { Stack } from 'wui/Stack';
import { Show } from 'wui/Show';
import { Typography } from 'wui/Typography';
import { Popover } from 'wui/Popover';
import { CardContent } from 'wui/CardContent';
import { TextButton } from 'wui/TextButton';

import { TopicLink } from '../../TopicLink';

interface IProps {
  item: IFeedItem;
  limit?: number;
}

export function FeedItemTopics(props: IProps) {
  const { item } = props;

  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useEnvironment();
  const { params } = useCurrentStateAndParams();

  const groupId = item.applicationContext?.contextId as string;
  const topics = item.entity?.topics;
  const activeId = params[EFilterKeys.TOPICS];

  if (!topics.length) {
    return null;
  }

  const items = topics.slice(0, props.limit);
  const rest = topics.slice(props.limit);

  return (
    <CardContent>
      <Stack wrap verticalAlign="middle" gap="SP2">
        <Typography as="span">
          <TagIcon />
        </Typography>
        {items.map((topic) => (
          <TopicLink
            wired
            key={topic.id}
            topic={topic}
            groupId={groupId}
            active={topic.id === activeId}
            isRemovable={topic.id === activeId}
            ellipsis
          />
        ))}
        <Show if={!!rest.length}>
          <Popover
            isOpen={isOpen}
            placement={isMobile ? 'top-start' : 'top'}
            onClose={handleTopicsClose}
            content={
              <Stack wrap verticalAlign="middle" gap="SP1">
                {rest.map((topic) => (
                  <TopicLink
                    key={topic.id}
                    topic={topic}
                    groupId={groupId}
                    active={topic.id === activeId}
                    isRemovable={topic.id === activeId}
                    ellipsis
                  />
                ))}
              </Stack>
            }
          >
            <Typography variant="p2-14" as="span">
              <TextButton variant="secondary" onClick={handleTopicsOpen}>
                + {rest.length}
              </TextButton>
            </Typography>
          </Popover>
        </Show>
      </Stack>
    </CardContent>
  );

  function handleTopicsOpen() {
    setIsOpen(true);
  }

  function handleTopicsClose() {
    setIsOpen(false);
  }
}

FeedItemTopics.displayName = 'FeedItemTopics';
FeedItemTopics.defaultProps = {
  limit: 2,
};
